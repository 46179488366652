<template>
  <div>
    <form-answer
      @newQuestionComment="getNextOrPrevQuestion(currentQuestionId)"
      @newAnswer="getNextOrPrevQuestion(currentQuestionId)"
      @nextOrPrevQuestion="getNextOrPrevQuestion"
      @answerRejected="getNextOrPrevQuestion(currentQuestionId)"
      @answerRetrieved="getNextOrPrevQuestion(currentQuestionId)"
      @answerMarkReplaced="getNextOrPrevQuestion(currentQuestionId)"
      @answerDeleted="getNextOrPrevQuestion(currentQuestionId)"
      @newMissingImage="getNextOrPrevQuestion(currentQuestionId)"
      @updateEnableQuestion="getNextOrPrevQuestion(currentQuestionId)"
      :checklist="checklist"
      :placeChecklist="placeChecklist"
      :currentPlaceUser="currentPlaceUser"
      :breadcrumb="breadcrumb"
      :currentQuestion="currentQuestion"
      :modelId="$route.params.id"
      :createAnswer="createAnswer"
      :rejectAnswer="rejectAnswer"
      :deleteAnswer="deleteAnswer"
      :progress="checklistProgress"
      :questions="questions"
    >
    </form-answer>
  </div>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import { mapActions, mapGetters } from "vuex";
import FormAnswer from "@/view/pages/app/questions/components/FormAnswer.vue";

export default {
  components: {
    Breadcrumb,
    PageTitle,
    FormAnswer,
  },
  mounted() {
    this.getPlace(this.$route.params.id);
    this.getPlaceUsers({
      place_id: this.$route.params.id,
    });
    this.getChecklist(this.$route.params.checklistId);
    this.getQuestions({
      checklist_id: this.$route.params.checklistId,
      place_id: this.$route.params.id,
    });
    this.getCurrentQuestion();
    this.getPlaceChecklists({
      checklist_id: this.$route.params.checklistId,
      place_id: this.$route.params.id,
    });
  },
  data() {
    return {
      currentQuestion: null,
      currentQuestionId: null,
      checklistProgress: 0,
    };
  },
  methods: {
    ...mapActions({
      getPlace: "place/getPlace",
      getChecklist: "checklist/getChecklist",
      getPlaceUsers: "place/getPlaceUsers",
      getNextChecklistQuestion: "question/getNextChecklistQuestion",
      createAnswer: "question/createAnswer",
      rejectAnswer: "question/rejectAnswer",
      deleteAnswer: "question/deleteAnswer",
      getPlaceChecklistProgress: "place/getPlaceChecklistProgress",
      getQuestions: "question/getQuestions",
      getPlaceChecklists: "place/getPlaceChecklists",
    }),
    getCurrentQuestion() {
      this.getNextQuestion({
        checklist_id: this.$route.params.checklistId,
        place_id: this.$route.params.id,
      });
    },
    getNextOrPrevQuestion(id, unapproved_only = undefined) {
      this.getNextQuestion({
        checklist_id: this.$route.params.checklistId,
        question_id: id,
        place_id: this.$route.params.id,
        unapproved_only,
      });
    },
    async getNextQuestion(params) {
      this.currentQuestion = null;
      this.currentQuestion = await this.getNextChecklistQuestion(params);
      this.currentQuestionId = this.currentQuestion.id;
      this.fetchAndSetCheklistProgress();
    },
    async fetchAndSetCheklistProgress() {
      const { percentage } = await this.getPlaceChecklistProgress({
        place_id: this.$route.params.id,
        checklist_id: this.$route.params.checklistId,
      });
      this.checklistProgress = percentage;
    },
  },
  computed: {
    ...mapGetters({
      checklist: "checklist/checklist",
      questions: "question/questions",
      place: "place/place",
      placeUsers: "place/placeUsers",
      currentUser: "auth/currentUser",
      placeChecklist: "place/placeChecklist",
    }),
    breadcrumb() {
      if (this.place) {
        return [
          {
            path: "/app/projects",
            text: "Proyectos",
          },
          {
            path: `/app/projects/${this.place.project.id}/places`,
            text: this.place.project.name,
          },
          {
            path: `/app/places/${this.$route.params.id}/checklists`,
            text: this.place.name,
          },
        ];
      }
      return [
        {
          path: "/app/projects",
          text: "Proyectos",
        },
      ];
    },
    currentPlaceUser() {
      if (this.currentUser) {
        return this.placeUsers.find(
          (placeUser) => placeUser.customuser.id == this.currentUser.id
        );
      }
      return null;
    },
  },
};
</script>
<style></style>
